import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a className="sel" href="/otazky_a_odpovede/">
                    Otázky a odpovede
                  </a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/otazky_a_odpovede/" className="sel">
              {" "}
              &gt; Otázky a odpovede
            </a>
          </div>
          <div id="page_content">
            <div className="col2">
              <h1>Otázky a odpovede</h1>
              <div className="row">
                <p className="title">
                  Ako dlho možno používať nosové roztokové spreje OLYNTH
                  <sup>®</sup>?
                </p>
                <div className="desc">
                  <div className="wrapper">
                    <div>
                      <p
                        className="p1"
                        style={{
                          "-webkit-text-align": "justify",
                          "text-align": "justify"
                        }}
                      >
                        Liečivom v nosových roztokových sprejoch OLYNTH
                        <sup>®</sup>* je{" "}
                        <a
                          href="/slovnik_pojmov/#xylometazolin"
                          target="_blank"
                        >
                          xylometazolín
                        </a>
                        . Nosové roztokové spreje sa nemajú používať
                        dlhšie ako 7 dní, ak lekár neodporučí inak. Opätovné
                        používanie možno začať po niekoľkodňovej prestávke. Pred
                        použitím lieku si pozorne prečítajte písomnú informáciu
                        pre používateľa alebo sa poraďte so svojím lekárom alebo
                        lekárnikom.
                      </p>
                      <p
                        className="p2"
                        style={{
                          "-webkit-text-align": "justify",
                          "text-align": "justify"
                        }}
                      >
                        &nbsp;
                      </p>
                      <p
                        className="p1"
                        style={{
                          "-webkit-text-align": "justify",
                          "text-align": "justify"
                        }}
                      >
                        * OLYTH<sup>®</sup> HA 0,1 %, OLYNTH<sup>®</sup> 0,1 %,
                        OLYNTH<sup>®</sup> 0,05 %, OLYNTH<sup>®</sup> HA 0,05 %,
                        OLYNTH<sup>®</sup> PLUS 0,5 mg/50 mg/ml nosový roztokový sprej,
                        OLYNTH<sup>®</sup> PLUS 1 mg/50 mg/ml nosový roztokový sprej
                      </p>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row">
                <p className="title">
                  Mám stále upchatý nos, ale podľa priloženej informácie pre
                  používateľa nosový sprej nemôžem používať dlhodobo. Existuje
                  nejaká alternatíva?
                </p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      Nosový sprej sa nemá používať dlhodobo. Jeho používanie sa
                      odporúča na niekoľko dní vynechať. Potom je možné nosový
                      sprej opäť začať používať. Počas obdobia, kedy nemôžete
                      používať nosový sprej, môžete na zvlhčenie nosovej
                      sliznice použiť nosové kvapky alebo spreje obsahujúce
                      napr. morskú vodu a v nej obsiahnuté minerály. „Domácim“
                      riešením môže byť aj preplachovanie nosa obyčajnou slanou
                      vodou.
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row">
                <p className="title">
                  Aký je rozdiel medzi používaním nosových sprejov s liečivom a
                  sprejov obsahujúcich morskú vodu?
                </p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      Nosové spreje obsahujú liečivo, ktoré pri presnom
                      dodržiavaní pokynov na používanie uvedených v písomnej
                      informácii pre používateľa, uvoľní upchatý nos a uľaví od
                      príznakov nádchy. Nosové spreje s obsahom liečiva sú
                      lieky. Nosové kvapky a spreje obsahujúce morskú vodu
                      slúžia ako doplnok, prípadne na prevenciu pri
                      starostlivosti o nosovú sliznicu a jej zvlhčovaní.{" "}
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row">
                <p className="title">
                  V čom spočíva špeciálna technológia použitá pri výrobe nosového roztokového spreja{" "}
                  <span className="nowrap">
                    OLYNTH<sup>®</sup> HA 0,1 % a OLYNTH® HA 0,05 %?
                  </span>
                </p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        Patentovaná konštrukcia pumpičky pozostávajúcej zo
                        striebornej špirály a systému filtrov 3K zaručuje
                        sterilitu lieku, vďaka čomu OLYNTH<sup>®</sup> HA 0,1 % a OLYNTH® HA 0,05 %
                        neobsahuje konzervačné látky a môže sa používať po dobu
                        12 mesiacov od otvorenia.
                      </span>
                    </p>
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      &nbsp;
                    </p>
                    <video controls width="100%">
                      <source
                        src="/kcfinder/uploads/files/film/ursatec_sk_30_01_2016v2.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row">
                <p className="title">
                  Čo je príčinou vzniku nádchy a ako ochorenie prebieha?
                </p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      Nádcha môže mať až 200 rôznych pôvodcov, ale v drvivej
                      väčšine prípadov za to môže rinovírus. Ten sa do vášho
                      tela dostáva ústami alebo nosom, pretože sa šíri
                      kvapôčkami vo vzduchu - napr. keď chorý zakašle, kýcha
                      alebo rozpráva. To však nie je jediný spôsob, ako je možné
                      sa nakaziť. Prenos infekcie prebieha aj fyzickým kontaktom
                      s chorým alebo s predmetmi, ktoré používa.
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row">
                <p className="title">
                  Ako dlho od kontaktu s vírusom trvá, kým ochoriem?
                </p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      Poznáte to povedomé škriabanie v krku, nos stále viac
                      upchatý a vy si hovoríte „čo sa deje?“. Spomeňte si, kde
                      ste boli a čo ste robili pred<br />
                      48 hodinami – je totiž veľmi
                      pravdepodobné, že práve vtedy ste sa nakazili. Potom, čo
                      sa dostane do vášho tela, trvá rinovírusu zvyčajne<br />
                      15 minút, kým sa aktivuje. Následne je to deň až dva,
                      výnimočne aj štyri, kým sa infekcia začne prejavovať a vy
                      pocítite ťažkosti. Ideálnou teplotou na množenie
                      rinovírusu je 33 – 35 °C, na čo je vhodne prostredie práve v nose.
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row">
                <p className="title">Ako rýchlo sa môžem zbaviť nádchy?</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      Pri dodržaní zvyčajných liečebných postupov trvá liečba
                      približne týždeň. Pokiaľ váš stav nie je po tomto čase
                      uspokojivý, je potrebné hľadať príčiny vo svojom okolí,
                      napríklad nedostatok odpočinku, nevhodné klimatické
                      podmienky v prekúrenej domácnosti. Takisto je možné, že <br />v
                      danom čase prekonávate súbežne iné ochorenie, alebo je
                      telo oslabené napr. stresom. U žien prichádza nádcha
                      častejšie aj v čase menštruácie, v tehotenstve alebo počas klimaktéria.
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row">
                <p className="title">
                  Môžem sa nádchou nakaziť znovu po jej prekonaní?
                </p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      Po vyliečení z prechladnutia ste imúnní približne 10 dní, potom vás môže nádcha opäť napadnúť.
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row">
                <p className="title">
                  Môžem dostať nádchu od králika alebo iného domáceho maznáčika?
                </p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      Áno, môžete! Hoci je to málo uveriteľné, takýto spôsob
                      nákazy je skutočne možný. Ten, kto vezme prechladnutého
                      domáceho maznáčika na prehliadku vie, že veterinár vždy
                      dôrazne varuje pred možným prenosom nádchy na človeka.
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row">
                <p className="title">Aké sú príznaky nádchy?</p>
                <div className="desc">
                  <div className="wrapper">
                    <div>
                      Nádcha, alebo rinitída, je odborne definovaná ako zápal
                      nosovej sliznice, pri ktorom dochádza k opuchu nosovej
                      sliznice a zvýšenej tvorbe hlienu.
                    </div>
                    <div>Medzi najčastejšie príznaky nádchy patrí:</div>
                    <div>- opuch nosovej sliznice</div>
                    <div>- zvýšené prekrvenie nosovej sliznice</div>
                    <div>- upchatý nos</div>
                    <div>- výtok z nosa</div>
                    <div>- porucha čuchu</div>
                    <div>- kýchanie</div>
                    <div>- svrbenie</div>
                    <div>- dráždenie v nose</div>
                    <div>
                      - bolesti hlavy alebo tlak v tvári a čelových dutinách
                    </div>
                    <div>- svrbenie a pálenie očí</div>
                    <div>- pálenie v krku</div>
                    <div>- celková únava</div>
                    <div>- zvýšená telesná teplota</div>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row">
                <p className="title">Čo je dexpantenol a aké sú jeho vlastnosti?</p>
                <div className="desc">
                  <div className="wrapper">
                    <div>
                    Dexpanthenol je provitamín B5¹, ktorý urýchľuje proces hojenia podráždenej alebo 
                    poškodenej nosovej sliznice¹. Zároveň nosovú sliznicu chráni a urýchľuje proces 
                    hojenia po operáciách nosa alebo prinosových dútin<sup>2</sup>.
                    </div>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row">
              <p className="title">Aký je rozdiel medzi liekmi OLYNTH<sup>®</sup> HA a OLYNTH<sup>®</sup> PLUS?</p>
                <div className="desc">
                  <div className="wrapper">
                    <div>
                    Každý zo sprejov z radu OLYNTH<sup>®</sup> uvoľňuje upchatý nos a znižuje množstvo hlienu.<br/>
                    OLYNTH<sup>®</sup> HA navyše zvlhčuje nosovú sliznicu vďaka trom zvlhčujúcim látkam a je možné ho 
                    používať aj pri alergickom zápale nosovej sliznice. Uľahčuje odstránenie hlienu počas
                    zápalu nosových dutín a zápalu stredného ucha.<br />
                    OLYNTH<sup>®</sup> PLUS chráni a urýchľuje hojenie poranenej nosovej sliznice. Jeho použitie je vhodné 
                    aj pri liečbe sťaženého dýchania nosom po operáciách nosa.
                    </div>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row">
                <p className="title">Obsahujú lieky OLYNTH<sup>®</sup> HA a OLYNTH<sup>®</sup> PLUS konzervačné látky?</p>
                <div className="desc">
                  <div className="wrapper">
                    <div>
                    Lieky OLYNTH<sup>®</sup> HA a OLYNTH<sup>®</sup> PLUS neobsahujú žiadne konzervačné látky vďaka jedinečnému 
                    spôsobu balenia. Konzervačné látky môžu spôsobovať negatívne zmeny nosovej sliznice,
                    ako je podráždenie a vznik raniek, a tiež zosilnenie kongescie spôsobené alergiami
                    </div>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="wysiwyg">
<ul>
  <li>1. Ebner F, Heller A, Rippke F, Tausch I. Topical use of dexpanthenol in skin disorders. Am J Clin Dermatol. 2002;3(6):427-33.</li>
  
              <li>
2. Gouteva I, Shah-Hosseini K, Meiser P. Clinical efficacy of a spray containing hyaluronic Acid and dexpanthenol after surgery in the nasal cavity
(septoplasty, simple ethmoid sinus surgery, and turbinate surgery). J Allergy (Cairo). 2014;2014:635490.</li></ul>


              </div>
            </div>
            <div className="col1">
              <div className="related">
                <p className="name">Zistiť viac</p>
                <div className="line">
                  <a href="/radca/nadcha/spoznajte_typy_nadchy/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/artykuly/shutterstock_388715146.jpg)"}} />
                    </div>
                    <div className="c2">Spoznajte typy nádchy </div>
                  </a>
                </div>
                <div className="line">
                  <a href="/radca/prechladnutie/domace_sposoby_boja_s_prechladnutim/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/artykuly/GettyImages-1030072086.jpg)"}} />
                    </div>
                    <div className="c2">
                      Domáce spôsoby boja s prechladnutím{" "}
                    </div>
                  </a>
                </div>
              </div>
              <a href="/upravte_liek/" className="link_box link">
                <img src="/kcfinder/uploads/files/box/sk/Test_SK.png" />
              </a>
              <a href="/otazky_a_odpovede/" className="quiz_btn" />
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
